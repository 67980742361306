@font-face {
  font-family: 'cb-fontello';
  src: url('/editor/css/font/cb-fontello.eot?69544295');
  src: url('/editor/css/font/cb-fontello.eot?69544295#iefix') format('embedded-opentype'),
       url('/editor/css/font/cb-fontello.woff?69544295') format('woff'),
       url('/editor/css/font/cb-fontello.ttf?69544295') format('truetype'),
       url('/editor/css/font/cb-fontello.svg?69544295#cb-fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'cb-fontello';
    src: url('../font/cb-fontello.svg?69544295#cb-fontello') format('svg');
  }
}
*/
 
 [class^="cb-icon-"]:before, [class*=" cb-icon-"]:before {
  font-family: "cb-fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: content-box;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.cb-icon-home:before { padding:5px; content: '\e800'; } /* '' */
.cb-icon-fontsize:before { padding:5px; content: '\e900'; } /* '' */
.cb-icon-italic:before { padding:5px; content: '\e901'; } /* '' */
.cb-icon-indent-left:before { padding:5px; content: '\e902'; } /* '' */
.cb-icon-indent-right:before { padding:5px; content: '\e903'; } /* '' */
.cb-icon-unlink:before { padding:5px; content: '\e904'; } /* '' */
.cb-icon-link:before { padding:5px; content: '\e905'; } /* '' */
.cb-icon-align-left:before { padding:5px; content: '\e906'; } /* '' */
.cb-icon-align-center:before { padding:5px; content: '\e907'; } /* '' */
.cb-icon-align-right:before { padding:5px; content: '\e908'; } /* '' */
.cb-icon-align-justify:before { padding:5px; content: '\e909'; } /* '' */
.cb-icon-list-numbered:before { padding:5px; content: '\e90a'; } /* '' */
.cb-icon-list-bullet:before { padding:5px; content: '\e90b'; } /* '' */
.cb-icon-camera:before { padding:5px; content: '\e90c'; } /* '' */
.cb-icon-move:before { padding:5px; content: '\e90d'; } /* '' */
.cb-icon-eraser:before { padding:5px; content: '\e90e'; } /* '' */
.cb-icon-pencil:before { padding:5px; content: '\e90f'; } /* '' */
.cb-icon-underline:before { padding:5px; content: '\e910'; } /* '' */
.cb-icon-bold:before { padding:5px; content: '\e911'; } /* '' */
.cb-icon-strike:before { padding:5px; content: '\e912'; } /* '' */
.cb-icon-code:before { padding:5px; content: '\e913'; } /* '' */
.cb-icon-plus:before { padding:5px; content: '\e914'; } /* '' */
.cb-icon-spin:before { padding:5px; content: '\e915'; } /* '' */
.cb-icon-docs:before { padding:5px; content: '\e916'; } /* '' */
.cb-icon-cancel:before { padding:5px; content: '\e917'; } /* '' */
.cb-icon-color:before { padding:5px; content: '\e918'; } /* '' */
.cb-icon-tint:before { padding:5px; content: '\e919'; } /* '' */
.cb-icon-header:before { padding:5px; content: '\e91a'; } /* '' */
.cb-icon-font:before { padding:5px; content: '\e91b'; } /* '' */
.cb-icon-picture:before { padding:5px; content: '\e91d'; } /* '' */
.cb-icon-superscript:before { padding:5px; content: '\e91e'; } /* '' */
.cb-icon-subscript:before { padding:5px; content: '\e91f'; } /* '' */
.cb-icon-dot:before { padding:5px; content: '\e920'; } /* '' */
.cb-icon-trash:before { padding:5px; content: '\e921'; } /* '' */
.cb-icon-ok:before { padding:5px; content: '\e922'; } /* '' */
.cb-icon-download:before { padding:5px; content: '\e923'; } /* '' */
.cb-icon-doc:before { padding:5px; content: '\e924'; } /* '' */
.cb-icon-cog:before { padding:5px; content: '\e925'; } /* '' */
.cb-icon-off:before { padding:5px; content: '\e926'; } /* '' */
.cb-icon-minus:before { padding:5px; content: '\e927'; } /* '' */
.cb-icon-back:before { padding:5px; content: '\e928'; } /* '' */
.cb-icon-left-open-big:before { padding:5px; content: '\e929'; } /* '' */
.cb-icon-right-open-big:before { padding:5px; content: '\e930'; } /* '' */
.cb-icon-video:before { padding:5px; content: '\e931'; } /* '' */
.cb-icon-wrench:before { padding:5px; content: '\e932'; } /* '' */
.cb-icon-file-code:before { padding:5px; content: '\e933'; } /* '' */
.cb-icon-table:before { padding:5px; content: '\e934'; } /* '' */
.cb-icon-ticket:before { padding:5px; content: '\e935'; } /* '' */
.cb-icon-smile:before { padding:5px; content: '\e936'; } /* '' */
.cb-icon-cubes:before { padding:5px; content: '\e937'; } /* '' */
.cb-icon-brush:before { padding:5px; content: '\e938'; } /* '' */