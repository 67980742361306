/** Easy Tree style */
.easy-tree {
    min-height: 20px;
    color: #337ab7;
    border: none;
    border-top: none;
    position: relative;
    padding: 5px 0px 0px;
    margin-bottom: 0px;
}

.easy-tree-s {
    content: "\f0f6";
    font-size: 15em;
    opacity: 0.1;
    color: #ccc;
    position: absolute;
    right: 0px;
    top: 3px;
    word-break: break-all;
    font-family: FontAwesome;
    font-weight: 900;
    z-index: -1;

}

.easy-tree > ul {
    padding-left: 0px;
    visibility: hidden;
}

.easy-tree li {
    list-style-type: none;
    margin: 0;
    padding: 10px 5px 0 5px;
    position: relative;
    text-align: left;
}

.easy-tree li::before, .easy-tree li::after {
    content: '';
    left: -30px;
    position: absolute;
    right: auto
}

.easy-tree li::before {
    border-left: 1px solid #ccc;
    bottom: 50px;
    height: 130%;
    top: 0;
    width: 1px
}

.easy-tree li::after {
    border-top: 1px solid #ccc;
    height: 20px;
    top: 30px;
    width: 35px
}

.easy-tree li > span {
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    padding: 5px;
    text-decoration: none;
    background: #fff;
}

.easy-tree li.parent_li > span {
    cursor: pointer
}

.easy-tree > ul > li::before, .easy-tree > ul > li::after {
    border: 0;
}

.easy-tree li:last-child::before {
    height: 30px
}

.easy-tree-editor {
    color: #ccc;
}

.easy-tree li.parent_li > span:hover, .easy-tree li.parent_li > span:hover + ul li span {
    background: #337ab7;
    color: #fff;
}

.easy-tree li.parent_li > span:hover + ul li.li_selected span {
    background: #ccc;
    color: #fff;
}

.easy-tree li.parent_li > span:hover + ul li span a, .easy-tree li.parent_li > span:hover a {
    color: #fff !important;
}

.easy-tree li > span > a {
    text-decoration: none;
    width: 100%;
}

.easy-tree li > span > span.glyphicon-folder-close, .easy-tree li > span > span.glyphicon-folder-open {
    margin-right: 5px;
}

.easy-tree li.li_selected > span, .easy-tree li.li_selected > span > a {
    background: #337ab7;
    color: #fff;
}

.easy-tree li.li_selected > span:hover, .easy-tree li.li_selected > span:hover > a {
    background: #337ab7;
    color: #fff !important;
}

.easy-tree .easy-tree-toolbar {
    background-color: #f8f8f8;
    padding: 5px;
    text-align: left;
}

.easy-tree .easy-tree-toolbar > div {
    display: inline-block;
}

.easy-tree .easy-tree-toolbar > div > button {
    margin: 5px;
}

.easy-tree .easy-tree-toolbar .create .input-group {
    margin: 15px 5px;
}

.easy-tree .easy-tree-toolbar .create .input-group input {
    border-radius: 0;
}

.easy-tree .easy-tree-toolbar .create .input-group button {
    border-radius: 0;
}

.easy-tree-toolbar .btn {
    border-radius: 5px;
    padding: 5px 12px;
    background: #fff;
    color: #337ab7;
    box-shadow: 0px 0px 10px #ccc;
}


.easy-tree ul li {
    border-top: 1px solid #dedede;
    padding: 10px 0px 5px 15px;
    margin: 8px 0px;
}

.easy-tree ul li:last-child {
    border-bottom: 1px solid #dedede;
}
.easy-tree ul li ul li, .easy-tree ul li ul li:last-child{
    border: none;
}


.easy-tree ul li li {
    background: none;
}

.easy-tree .alert-dismissable {
    padding-right: 35px;
    background: #ffffff;
    margin: 0px;
}

.easy-tree .closes {
    border: 1px solid #fbe5d7;
    padding: 6px 10px;
    background: #f8f8f8;
}

.easy-tree .alert-dismissable .closes {
    right: 10px;
    top: 10px;
    position: absolute;
}

.easy-tree .confirm {
    background: #347ab7;
    color: #fff;
    border-color: #fff;
}

.easy-tree .input-group {
    padding: 5px;
    width: 200px;
}

.easy-tree .input-group input {
    width: 200px;
}