html {
  position: relative;
  min-height: 100%;
}
BODY {
  padding-bottom: 150px;
  font: 13px/18px Arial, Helvetica, sans-serif;
}
header {
  box-shadow: 0px 0px 10px #ccc;
  margin-bottom: 20px;
}
#content {
  min-height: 300px;
  padding-bottom: 60px;
}
#content h1,
#content h2,
#content h3,
#content h4,
#content h5 {
  color: #164476;
}
.navbar-brand {
  width: 150px;
  height: inherit;
  padding: 5px;
}
.top-errors {
  color: #fff;
  background-color: red;
  font-size: 1.8em;
  padding: 10px 0px;
}
.igenylo {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: rgba(247, 247, 247, 0.2);
  z-index: 99;
}
footer {
  box-shadow: 0px -5px 10px #ccc;
  color: #fff;
  background: -webkit-linear-gradient(#164476, #057fc2);
  background: linear-gradient(#164476, #057fc2);
  position: absolute;
  bottom: 0;
  padding: 10px;
  width: 100%;
  height: auto;
}
.page_2 #content .pdf-view iframe {
  width: 88%;
  float: left;
}
.page_2 #content .pdf-view .next,
.page_2 #content .pdf-view .prev {
  float: right;
  width: 5%;
  vertical-align: middle;
  line-height: 400px;
}
.page_2 #content .pdf-view .prev {
  float: left;
}

/*# sourceMappingURL=main.css.map */
